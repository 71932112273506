import LocalizedStrings from 'react-localization'

let deliveries = new LocalizedStrings({
  en: {
    deliveries: 'Deliveries',
    track: 'Track',
    packageId: 'Package ID',
    orderTime: 'Order time',
    status: 'Status',
    pickup: 'Pickup',
    handover: 'Handover',
    ordered: 'Ordered',
    picked_up: 'Picked up',
    delivered: 'Delivered',
    cancelled: 'Cancelled',
    unknown: 'Unknown',
    printPackageIdStickers: 'Print package Id stickers',
    price: 'Price'
  },
  hu: {
    deliveries: 'Kiszállítások',
    track: 'Csomagkövetés',
    packageId: 'Csomag azonosító',
    orderTime: 'Rendelési idő',
    status: 'Státusz',
    pickup: 'Felvétel',
    handover: 'Leadás',
    ordered: 'Megrendelve',
    picked_up: 'Felvéve',
    delivered: 'Kiszállítva',
    cancelled: 'Lemondva',
    unknown: 'Ismeretlen',
    printPackageIdStickers: 'Csomag ID matricák nyomtatása',
    price: 'Ár',
  },
  de: {
    deliveries: 'Lieferungen',
    track: 'Tracking',
    packageId: 'Paket-ID',
    orderTime: 'Auftragszeit',
    status: 'Status',
    pickup: 'Abholung',
    handover: 'Übergabe',
    ordered: 'Beauftragt',
    picked_up: 'Abgeholt',
    delivered: 'Zugestellt',
    cancelled: 'Storniert',
    unknown: 'Unbekannt',
    printPackageIdStickers: 'Drucken Sie die Paket-ID-Aufkleber',
    price: 'Preis',

  },
  it: {
    deliveries: 'Spedizioni',
    track: 'Tracciamento',
    packageId: 'ID pacco',
    orderTime: 'Orario ordine',
    status: 'Status',
    pickup: 'Ritiro',
    handover: 'Consegna',
    ordered: 'Ordinato',
    picked_up: 'Ritirato',
    delivered: 'Consegnato',
    cancelled: 'Cancellato',
    unknown: 'Sconosciuto',
    printPackageIdStickers: 'Stampa adesivi ID pacchetto',
    price: 'Prezzo',
  },
  ro: {
    deliveries: 'Livrări',
    track: 'Urmărește',
    packageId: 'ID Pachet',
    orderTime: 'Ora Comenzii',
    status: 'Status',
    pickup: 'Ridicare',
    handover: 'Predare',
    ordered: 'Comandat',
    picked_up: 'Ridicat',
    delivered: 'Livrat',
    cancelled: 'Anulat',
    unknown: 'Necunoscu',
    printPackageIdStickers: 'Imprimați autocolante',
    price: 'Preț',
  },
})

export default deliveries
